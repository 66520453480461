import axios from "axios";

class Push {
  getAll() {
    return axios.get(`/push`);
  }
  send(data) {
    return axios.post(`/push`, data);
  }
  getByClient(clientId) {
    return axios.get(`/push/by-client`, { params: { clientId } });
  }
}

export default new Push({});
