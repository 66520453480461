import React from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Tab,
  Tabs,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  CardActionArea,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // makeStyles,
} from "@mui/material";
import getDayNameByNumer from "../../../common/getDayNameByNumer";
import groupGetFullInfo from "../../../common/groupGetFullInfo";

import firstLetterUppercase from "../../../common/firstLetterUppercase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";
import "moment/locale/ru";
import TrainerStore from "../../../store/trainer";
import GroupStore from "../../../store/group";

// import

export default function MembershipCard({ data }) {
  console.log(data, "data");
  let trainerList = TrainerStore.list;
  let groupList = GroupStore.list;

  let group = groupList.find((g) => g._id == data.group);

  if (!group) return null;
  console.log(group, 'group card member')

  let trainer = trainerList.find((t) => t._id == group.trainer);

  let monthName = firstLetterUppercase(moment(data.month).format("MMMM YYYY"));

  return (
    <Accordion sx={{ mb: 1 }}>
      <AccordionSummary disableGutters={true} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{`${group.name} (${trainer.name}) | ${monthName} | ${data.paymentAmount} руб.`}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography sx={{ mt: 1 }} variant="body1">
          {group.name} |
          {data.visitDays.map((g) => getDayNameByNumer(+g)).join(",")} |
          {group.visitingTime}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1">
          {trainer.name}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          Месяц:
          {monthName}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          {data.paymentAmount} руб.
        </Typography>

        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          Дата оплаты:
          {moment(data.datePayment).format("DD.MMMM.YYYY ,в HH:mm")}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
