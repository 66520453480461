import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  Avatar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react-lite";
import TrainerStore from "../../store/trainer";
import { EventBus } from "../../event";
import { API_IMAGES } from "../../config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TrainerService from "../../services/Trainer";
import { useSnackbar } from "notistack";

function Trainers() {
  const { enqueueSnackbar } = useSnackbar();
  let list = TrainerStore.list;

  function changeHide(id, hide) {
    TrainerService.changeHide({ id, hide }).then((r) => {
      TrainerStore.changeTrainer(r.data);
      enqueueSnackbar("Видимость измененна!", { variant: "success" });
    });
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Тренеры
      </Typography>
      <Button
        sx={{ marginBottom: "20px" }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => EventBus.$emit("open_trainer_window", "new")}
      >
        Добавить
      </Button>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Специализация</TableCell>
              <TableCell>Показывать</TableCell>
              <TableCell>Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((t) => (
              <TableRow key={t._id}>
                <TableCell>
                  <Avatar
                    src={API_IMAGES + t.photo}
                    style={{ margin: "0px auto" }}
                  />
                </TableCell>
                <TableCell>{t.name}</TableCell>
                <TableCell>{t.spec}</TableCell>
                <TableCell>
                  <IconButton
                    sx={{ color: !t.hide ? "green" : "red" }}
                    onClick={() => changeHide(t._id, !t.hide)}
                  >
                    {t.hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => EventBus.$emit("open_trainer_window", t._id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default observer(Trainers);
