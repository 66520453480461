import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
    Avatar,
} from "@mui/material";
import SidebarWrap from "../SidebarWrap";
import Preview from "./Preview";
import InputWrap from "../InputWrap";
import { useSnackbar } from "notistack";

import MessageService from "../../services/Message";
import getPrivateImageUri from "../../common/file/getPrivateImageUri";

export default function Chat({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();

  let [isSubmiting, setSubmiting] = useState(false);
  let [previewImage, setPreviewImage] = useState(null);
  let [previewError, setPreviewError] = useState(null);
    let [client, setClient] = useState({});
    let [list, setList] = useState([]);
    let [getNewMessages, setGetNewMessages] = useState(true);

  let userPhone = data?.phone;
  const {
    register,
    handleSubmit,
    control, setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
        text: "",
    },
  });

    useEffect(() => {
        if (userPhone) {
            MessageService.getChat({phone: userPhone}).then(r => {
                setClient(r.data.account.client)
                setList(r.data.messages)
            })
        }
    }, []);
    useEffect(() => {
        if (getNewMessages) {
            setGetNewMessages(false)
            setTimeout(() => {
                MessageService.getChat({phone: userPhone}).then(r => {
                    setClient(r.data.account.client)
                    setList(r.data.messages)
                    let div = document.querySelector('#messagesContainer');
                    setTimeout(() => {
                        //div.scrollTop = div.scrollHeight;
                    }, 300)
                })
                setGetNewMessages(true)
            }, 3000)
        }
    }, [getNewMessages]);

  if (!data) return null;

  const onSubmit = handleSubmit((data) => {
      if (!previewImage && (!data.text || data.text.trim().length === 0)) {
          enqueueSnackbar('Напишите сообщение или прикрепите файл.', { variant: "error" });
          //setPreviewError("Добавьте файл!");
          return;
      }

      data.phone = userPhone;
      let fetchData = JSON.stringify(Object.assign({}, data));
      const form = new FormData();
      if (previewImage) {
          form.append("file", previewImage);
      }
      form.append("data", fetchData);
      setSubmiting(true);
      MessageService.send(form)
        .then((r) => {
             console.log(r)
            if (r.data.sended) {
                enqueueSnackbar("Сообщение отправлено!", { variant: "success" });
                //closeHandler();
                setValue('text', '')
                setPreviewImage(null)
                setClient(r.data.account.client)
                setList(r.data.messages)
            } else {
                enqueueSnackbar(r.data.reason, { variant: "error" });
            }
        })
          .catch(e=>{
              enqueueSnackbar('Неизвестная ошибка. Возможно неверный тип файла.', { variant: "error" });
          })
        .finally(() => setSubmiting(false));

  });

  return (
    <SidebarWrap
      title={`Чат`}
      closeHandler={closeHandler}
    >
        
        <div style={{display:'grid',gridTemplateColumns:'auto 1fr',gridGap:'20px'}}>
            <Avatar sx={{width:'70px',height:'70px'}} src={getPrivateImageUri(client.photo)}/>
            <div style={{alignSelf:'center'}}>
                {client.surname} {client.name}
                <div style={{color:'grey',marginTop:'5px'}}>
                    +{userPhone}
                </div>
            </div>
        </div>
        <div style={{margin:'10px 0',background:'#5b5b5b',maxHeight:'50vh',overflow:'hidden',borderRadius:'10px'}}>
            <div id={'messagesContainer'} style={{flexFlow: 'column-reverse nowrap',display:'flex',maxHeight:'50vh',overflowY:'auto'}}>
                {list.slice().reverse().map(mes=>
                    <div style={{display:'grid',position:'relative',margin:'5px 0',}}>
                        <div style={{maxWidth:'80%',justifySelf:mes.from === 'admin' ? 'end' : 'start',display:'grid',gridGap:'5px'}}>
                            {mes.file && <img style={{borderRadius:'10px',maxWidth:'100%',objectFit:'contain'}} src={getPrivateImageUri(mes.file)} />}
                            {mes.text && mes.text.length > 0 && <div style={{background:mes.from === 'admin' ? '#FF0008' : '#1C1C1E',padding:'10px',borderRadius:'10px',}}>{mes.text}</div>}
                        </div>
                    </div>
                )}
            </div>
        </div>

        <div style={{display:'grid',}}>
            <Preview
                setImage={setPreviewImage}
                image={previewImage}
                error={previewError}
                setError={setPreviewError}
            />

        </div>
        <form noValidate onSubmit={onSubmit}>

            <Controller
                name={"text"}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <InputWrap wrapStyle={{ my: 1 }} id="text">
                        <TextField
                            id="text"
                            label=""
                            error={Boolean(errors.text?.message)}
                            {...register("text", {
                            })}
                            fullWidth
                            margin="normal"
                            helperText={errors.text?.message}
                            sx={{ m: 0 }}
                            size="small"
                            variant="outlined"
                            name="text"
                            type="text"
                            onChange={onChange}
                            value={value}
                            placeholder={'Новое сообщение'}
                        />
                    </InputWrap>
                )}
            />
            <Button
                fullWidth
                sx={{ marginTop: 0 }}
                size="small"
                variant="contained"
                type="submit"
                startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
                disabled={isSubmiting}
            >
                Отправить
            </Button>
        </form>

    </SidebarWrap>
  );
}
