import axios from "axios";

class Group {
  change(data) {
    return axios.put(`/group`, data);
  }
  getAll() {
    return axios.get(`/group`);
  }
  create(data) {
    return axios.post(`/group`, data);
  }
  changeHide(data) {
    return axios.put("/group/hide", data);
  }
}

export default new Group({});
