import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import TrainerReview from "../../services/TrainerReview";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DateRange } from "react-date-range";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import TrainerStore from "../../store/trainer";
// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

moment.locale("ru");

function Reviews() {
  let [list, setList] = useState([]);

  let currentClubId = ClubStore.currentClubId;
  useEffect(() => {
    console.log({currentClubId})
    TrainerReview.getAll().then((res) => {
      setList(res.data);
    });
  }, [currentClubId]);

  async function changeRatingHandler(d) {
    try {
      let res = await TrainerReview.change(d);
      let data = res.data;

      if (d.action == "delete") {
        let newList = list.filter((r) => r._id !== d.ratingId);
        setList(newList);
      } else if (d.action == "moderate") {
        let newList = list.map((r) => {
          if (r._id == data._id) {
            return data;
          } else {
            return r;
          }
        });
        setList(newList);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Дата</TableCell>
            <TableCell>Клиент</TableCell>
            <TableCell>Тренер</TableCell>
            <TableCell>Оценка</TableCell>
            <TableCell>Отзыв</TableCell>
            <TableCell>Опубликовано</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => {
            let clientName = `${row.client.surname} ${row.client.name} ${row.client.patronymic}`;
            let trainer = TrainerStore.list.find((t) => t._id == row.trainer);
            return (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {moment().utc(row.created_at).format("DD.MM.YYYY HH:mm")}
                </TableCell>
                <TableCell>{clientName}</TableCell>
                <TableCell>{trainer.name}</TableCell>
                <TableCell>{row.rating}</TableCell>
                <TableCell>{row.review}</TableCell>
                <TableCell>
                  {row.isModerated ? "Опубликовано" : "Не опубликовано"}
                </TableCell>
                <TableCell align="center">
                  {!row.isModerated && (
                    <IconButton
                      onClick={() =>
                        changeRatingHandler({
                          action: "moderate",
                          ratingId: row._id,
                        })
                      }
                    >
                      <DoneIcon color="success" />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() =>
                      changeRatingHandler({
                        action: "delete",
                        ratingId: row._id,
                      })
                    }
                  >
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default observer(Reviews);
