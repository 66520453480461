import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import AddIcon from "@mui/icons-material/Add";
import ClientService from "../../services/Client";
import TelegramIcon from "@mui/icons-material/Telegram";
import ClientsTable from "./ClientsTable/index";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextsmsIcon from "@mui/icons-material/Textsms";
import Filter from "./Filter";
import StageFilter from "../../components/StageFilter";
import { EventBus } from "../../event";
import { socket } from "../../socket";
import fetchClients from "./methods/fetchClients";

// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";
let initFilterData = {
  clientName: "",
  childName: "",
  birthday: {
    from: 0,
    to: 99,
  },
  phone: "",
  status: [],
  month: moment().startOf("month").valueOf(),
  stage: [],
  level: [],
  source: [],
  group: [],
  trainer: [],
  withTelegram: false,
  hasTelegram: false,
};

const initPaginData = {
  page: 0,
  rowsPerPage: 25,
  countRows: 10,
};

const initSortData = {
  reg: null,
};

function Clients() {
  
  let [isSubmit, setSubmit] = useState(false);
  let [showFilter, setShowFilter] = useState(true);
  let [filterData, setFilterData] = useState(initFilterData);
  let [list, setList] = useState([]);
  let [stat, setStat] = useState({});
  let [paginData, setPaginData] = useState(initPaginData);
  let [allClientIds, setAllClientIds] = useState([]);
  let [allSelectedClients, setAllSelectedClients] = useState(false);
  let [selectedClients, setSelectedClients] = useState([]);
  let [sortData, setSortData] = useState(initSortData);

  let clubId = ClubStore.currentClubId;

  useEffect(()=>{
    fetchClients(
      filterData,
      initPaginData,
      isSubmit,
      setSubmit,
      setList,
      setStat,
      changePaginData,
      setAllClientIds,
      sortData
    );
  }, [clubId])

  useEffect(() => {
    fetchClients(
      filterData,
      paginData,
      isSubmit,
      setSubmit,
      setList,
      setStat,
      changePaginData,
      setAllClientIds,
      sortData
    );
    socket.on("update_client", (client) => {
      updateClientHandler(client);
    });
    return () => {
      socket.off("update_client");
    };
  }, []);

  function findHandler() {
    fetchClients(
      filterData,
      paginData,
      isSubmit,
      setSubmit,
      setList,
      setStat,
      changePaginData,
      setAllClientIds,
      sortData
    );
  }

  function changeSortData(name) {
    let currentValue = sortData[name];
    let newValue;

    switch (currentValue) {
      case null:
        newValue = 0;
        break;
      case 0:
        newValue = 1;
        break;
      case 1:
        newValue = null;
        break;
      default:
        newValue = null;
    }

    let newSortData = Object.assign({}, sortData, { [name]: newValue });

    setSortData(newSortData);
  }

  function setFilterHandler(id) {
    let filterData = Object.assign({}, initFilterData, { stage: [id] });
    let paginData = initPaginData;
    setFilterData(filterData);
    setPaginData(paginData);
    fetchClients(
      filterData,
      paginData,
      isSubmit,
      setSubmit,
      setList,
      setStat,
      changePaginData,
      setAllClientIds,
      sortData
    );
  }

  function changePaginData(key, value) {
    let newPaginData = Object.assign({}, paginData, { [key]: value });
    setPaginData((p) => Object.assign({}, p, { [key]: value }));
    return newPaginData;
  }

  const [anchorAction, setAnchorAction] = useState(null);

  function changeAllSelectedClients(val) {
    setAllSelectedClients(val);
    setSelectedClients(val ? allClientIds : []);
  }

  function changeSelectedClients(clientId) {
    let idx = selectedClients.indexOf(clientId);

    if (idx == -1) {
      setSelectedClients([...selectedClients, clientId]);
    } else {
      let newList = [...selectedClients];
      newList.splice(idx, 1);
      setSelectedClients(newList);
    }
  }

  // clients.length

  function changePaginDataHandler(name, value) {
    let paginData = changePaginData(name, value);
    fetchClients(
      filterData,
      paginData,
      isSubmit,
      setSubmit,
      setList,
      setStat,
      changePaginData,
      setAllClientIds,
      sortData
    );
  }

  const handleChangePage = (event, newPage) => {
    changePaginDataHandler("page", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = parseInt(event.target.value, 10);
    changePaginDataHandler("rowsPerPage", val);
  };

  function updateClientHandler(c) {
    setList((list) => {
      let clients = [...list];
      return clients.map((client) => {
        if (client._id == c._id) {
          return Object.assign({}, c, { membership: client.membership });
        } else {
          return Object.assign({}, client);
        }
      });
    });
  }

  function addClient(c) {
    setList([c, ...list]);
  }

  function changeMember(m) {
    let f = list.find((c) => c.membership._id == m._id);
    let newC = Object.assign({}, f, { membership: m });
    let newList = [...list];
    newList = newList.map((c) => {
      if (c._id == newC._id) {
        return newC;
      } else {
        return c;
      }
    });
    setList(newList);
  }

  // console.log(filterData, " filterData");
  return (
    <Box sx={{ position: "relative" }}>
      {isSubmit && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "#ffffff40",
            zIndex: 999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={60}
            sx={{ m: "20px auto", display: "block" }}
            disableShrink
          />
        </Box>
      )}

      <Typography variant="h5" gutterBottom>
        Клиенты
      </Typography>

      <Box>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => EventBus.$emit("open_edit_client_window", {})}
        >
          Добавить
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          startIcon={<FilterAltIcon />}
          onClick={() => setShowFilter(!showFilter)}
        >
          Фильтр
        </Button>
        {!!selectedClients.length && (
          <Button
            size="small"
            color="secondary"
            sx={{ ml: 2 }}
            variant="contained"
            startIcon={<ArrowDropDownIcon size="large" />}
            onClick={(e) => setAnchorAction(e.currentTarget)}
          >
            Действия
          </Button>
        )}
        <Menu
          anchorEl={anchorAction}
          open={!!anchorAction}
          onClose={() => {
            setAnchorAction(null);
          }}
        >
          <MenuItem
            onClick={() => {
              EventBus.$emit("modal_client_action", {
                action: "push-notify",
                data: selectedClients,
              });
              setAnchorAction(null);
            }}
          >
            <ListItemIcon>
              <MarkUnreadChatAltIcon />
            </ListItemIcon>
            <ListItemText>Пуш уведомление</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              EventBus.$emit("modal_client_action", {
                action: "sms-send",
                data: selectedClients,
              });
              setAnchorAction(null);
            }}
          >
            <ListItemIcon>
              <TextsmsIcon />
            </ListItemIcon>
            <ListItemText>SMS-сообщение</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              EventBus.$emit("modal_client_action", {
                action: "telegram-send",
                data: selectedClients,
              });
              setAnchorAction(null);
            }}
          >
            <ListItemIcon>
              <TelegramIcon />
            </ListItemIcon>
            <ListItemText>Telegram-сообщение</ListItemText>
          </MenuItem>
        </Menu>
      </Box>

      {showFilter && (
        <Filter
          filterData={filterData}
          setFilterData={setFilterData}
          findHandler={findHandler}
          initFilterData={initFilterData}
        />
      )}
      <StageFilter
        selectedFilter={filterData.stage}
        stat={stat}
        setFilterHandler={setFilterHandler}
      />

      <ClientsTable
        allSelectedClients={allSelectedClients}
        changeAllSelectedClients={changeAllSelectedClients}
        selectedClients={selectedClients}
        changeSelectedClients={changeSelectedClients}
        list={list}
        changeMember={changeMember}
        month={filterData.month}
        paginData={paginData}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        changeSortData={changeSortData}
        sortData={sortData}
      />
    </Box>
  );
}

export default observer(Clients);
