import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Alert,
  Modal,
  Box,
} from "@mui/material";

import moment from "moment";
import "moment/locale/ru";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/Loader/Loader";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
// import GroupService from "../../services/Group";
import getDayNameByNumer from "../../common/getDayNameByNumer";
import sortGroupByVisitTime from "../../common/sortGroupByVisitTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { observer } from "mobx-react-lite";
import GroupStore from "../../store/group";
import TrainerStore from "../../store/trainer";
import GroupService from '../../services/Group';
import ClubStore from '../../store/club';

import { EventBus } from "../../event";



function Groups() {
  const { enqueueSnackbar } = useSnackbar();
  let currentClubId = ClubStore.currentClubId;
  let groups = [...GroupStore.list].filter((g) => g.club == currentClubId);
  let trainers = TrainerStore.list;

  function changeHide(id, hide) {
    GroupService.changeHide({ id, hide }).then((r) => {
      GroupStore.changeGroup(r.data);
      enqueueSnackbar("Видимость измененна!", { variant: "success" });
    });
  }

  console.log(groups, 'groups')

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Группы
      </Typography>
      <Button
        sx={{ marginBottom: "20px" }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => EventBus.$emit("open_group_window", "new")}
      >
        Добавить
      </Button>
      <TableContainer >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Возраст</TableCell>
              <TableCell>Дни посещения</TableCell>
              <TableCell>Время</TableCell>
              <TableCell>Тренер</TableCell>
              <TableCell>Показывать</TableCell>
              <TableCell>Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((g) => (
              <TableRow key={g._id}>
                <TableCell>{g.name}</TableCell>
                <TableCell>
                  {g.age.from} - {g.age.to}
                </TableCell>
                <TableCell>
                  <Box sx={{ minWidth: "max-content" }}>
                    {g.visitingDays
                      .map((n) => getDayNameByNumer(n))
                      .map((n, i) => (
                        <Button
                          key={i}
                          size="small"
                          sx={{ mr: 1 }}
                          variant="contained"
                        >
                          {n}
                        </Button>
                      ))}
                  </Box>
                </TableCell>

                <TableCell>
                  {moment()
                    .set("hour", g.visitingTime.split(":")[0])
                    .set("minute", g.visitingTime.split(":")[1])
                    .format("HH:mm")}
                </TableCell>
                <TableCell>
                  {trainers.find((t) => t._id + "" == g.trainer + "")?.name}
                </TableCell>
                <TableCell>
                  <IconButton
                    sx={{ color: !g.hide ? "green" : "red" }}
                    onClick={() => changeHide(g._id, !g.hide)}
                  >
                    {g.hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </TableCell>

                <TableCell align="right">
                  <IconButton
                    onClick={() => EventBus.$emit("open_group_window", g._id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default observer(Groups);
